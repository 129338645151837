<template>
  <v-card>
    <v-card-text>
      <p>
        Exportiere eine detailierte Tourenstatistik deiner Tourengruppe. Aus der
        Statistik ist ersichtlich welche Tour wieviele Teilnehmer hatte und
        wecher Teilnhemer an welcher Tour teilgenommen hat.
      </p>
      <v-form v-model="valid">
        <v-row>
          <v-col sm="12" md="4">
            <v-autocomplete
              label="Tourengruppe"
              required
              :items="organisations"
              item-value="code"
              item-text="name"
              v-model="organisation"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col sm="12" md="4" class="pl-3">
            <v-autocomplete
              label="Jahr"
              required
              :items="years"
              v-model="year"
              :rules="[rules.required, rules.validYear]"
            />
          </v-col>
          <v-col md="4">
            <v-switch v-model="excludeCancelled" label="Ohne abgesagte Touren"></v-switch>
          </v-col>
          <v-col sm="12" md="12">
            <v-btn @click.stop="startExport" :disabled="!valid"
              >Exportieren</v-btn
            >
          </v-col>
        </v-row>

      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'pinia'

import downloadService from '@/services/download'
import { handleApiError } from '@/utils.js'
import { useEventStore } from '@/stores/event'

export default {
  name: 'MemberExportTool',
  data() {
    return {
      organisation: undefined,
      year: undefined,
      excludeCancelled: false,
      valid: false,
      rules: {
        required: (value) => !!value || 'Dieses Feld ist erforderlich',
        validYear: (value) => (value >= 2017 && value <= new Date().getFullYear()) || `Das Jahr muss zwischen 2017 und ${new Date().getFullYear()} liegen`,
      },
    }
  },
  computed: {
    years() {
      const start = 2017
      const end = new Date().getFullYear()
      return [...Array(1 + end - start).keys()].map(v => start + v)
    },
    ...mapState(useEventStore, {
      organisations: state => state.meta.organisations,
    }),
  },
  methods: {
    startExport() {
      downloadService.download(`/export/statistics/${this.organisation}/${this.year}?excludeCancelled=${this.excludeCancelled}`)
        .catch(error => {
          handleApiError(error, 'Fehler beim Laden der Datei')
        })
    },
  },
}
</script>
